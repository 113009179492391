<template>
  <div>
    <v-card
      max-width="344"
      min-width="400"
      outlined
      dark
    >
      <v-card-text>
        <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Имя"
        required
      ></v-text-field>

      <v-text-field
        v-model="pass"
        :rules="passRules"
        type="password"
        label="Пароль"
        required
      ></v-text-field>

      <v-btn
        :disabled="!valid"
        class="btn"
        @click="submit"
      >
        Войти
      </v-btn>

    </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
  export default {
    props: {
      messageOn: Function
    },
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Введите имя',
      ],
      pass: '',
      passRules: [
        v => !!v || 'Введите пароль',
      ],
    }),

    methods: {
      ...mapActions(['login']),
      ...mapGetters(['authStatus']),
      async submit () {
        if (this.$refs.form.validate()) {
          await this.login({user: this.name, pass: this.pass})
          if (this.authStatus() == 'success') {
            this.$router.push('/cost')
            this.messageOn(`Вы вошли как ${this.name}`, 'green')
          }
          else {
            this.messageOn(`Неверное имя пользователя или пароль`, 'red')
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .btn {
    margin-top: 15px;
  }
</style>